@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

$header-bg: linear-gradient(270deg, #52A8E2 -24.5%, #5851E5 56.07%, #AC4FE8 122.8%);
$placeholder-clr: #D6D6D6;
$white: white;
$black: black;

body {
    font-family: 'Poppins', sans-serif;
}

header {
    position: sticky;
    top: 0;
    z-index: 99;
    @apply sticky top-0 z-[999] bg-white;
}

.top_header {
    @apply bg-white py-3;
}

.top_header span {
    display: inline-block;
    width: auto;
}

.top_header span.phone {
    margin-left: auto;
}

.mob-header .navbar-wrapper,
.Footer .navbar,
.desktop-header .navbar {
    background: linear-gradient(270deg, #52A8E2 -24.5%, #5851E5 56.07%, #AC4FE8 122.8%);
    @apply py-4;
}

.navbar > .container-fluid .container {
    display: flex;
}

.navbar form {
    max-width: 590px;
    width: 100%;
    margin-left: auto;
    position: relative;
    padding: 0;
}

.navbar form .form-control {
    height: 50px;
    width: 100%;
    padding-left: 40px;
    @apply rounded-full max-md:hidden;
}

.navbar form .form-control::placeholder {
    font-size: 18px;
    font-weight: 300;
}

.navbar .search {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    left: 10px;
    background-color: transparent;
    border: none;
    padding: 0;
}

.navbar .search img {
    filter: brightness(0);
    -webkit-filter: brightness(0);
}

.navbar .nav-item {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    font-size: 13px;
    font-weight: 300;
    color: $white !important;
    cursor: pointer;
    @apply max-lg:px-3;
}

.navbar .nav-item .image {
    height: 34px;
    display: flex;
    align-items: end;
    justify-content: center;
}

.navbar .nav-item .label {
    margin-top: 5px;
    @apply inline-block w-full text-center;
}

.deliver-address {
    padding: 5px 0;
    background-color: #EFEFEF;
}

.deliver-address .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deliver-address .address {
    font-weight: 300;
    font-size: 12px;
}

.deliver-address .address strong {
    padding-right: 15px;
    font-weight: bold;
}

.deliver-address .icon {
    padding-left: 20px;
}

.header_bottom {
    @apply bg-white py-3;
}

.header_bottom .container {
    display: flex;
}

.category {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    padding: 0 15px;
    border: 1px solid #000;
    height: 50px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
}

.category .icon {
    display: flex;
}

.category a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
}

.category-default:hover {
    .nav__submenu {
        display: block;
    }
}

.offer {
    margin-left: 30px;
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.file_upload {
    display: inline-flex;
    align-items: center;
    padding: 0 25px;
    height: 50px;
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
    background-color: $black;
    margin-left: auto;
}

.file_upload a {
    color: $white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
}

/* search */
.search-wrapper {
    .search_result {
        @apply bg-white absolute top-full rounded-md border z-50 w-full mt-1;
        ul {
            @apply max-h-[500px] overflow-y-auto overflow-x-hidden p-3;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 8px;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-track {
                background: #f1f1f1;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-thumb {
                background: #ccc;
                @apply rounded-3xl;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            li {
                @apply pb-2 mb-2 border-b
            }
        }
    }
}

.category {
    @apply relative;
    .nav-submenu {
        @apply border rounded-md inline-block absolute z-50 bg-white top-full left-[2px] mt-[-1px] min-w-[100%] w-auto border-gray-200;
        .nav-submenu-item {
            @apply p-1.5 px-2 border-b border-gray-200;
            &:last-child {
                @apply border-b-0
            }

            a {
                @apply p-[2px] px-4 block rounded-full hover:bg-[#f2f2f2] hover:text-[#41BDDE] font-light text-sm;
                > p {
                    @apply flex justify-between;
                }
            }

            // .nav-subcategorymenu {
            //     @apply hidden;
            // }
        }
    }
}

@media (max-width: 991px) {
    header {
        .desktop-header {
        }

        .mob-header {
            .deliver-address {
                @apply max-md:py-2 max-md:px-2;
                .container {
                    @apply justify-start px-0;
                }

                .icon {
                    @apply pl-2;
                }
            }

            .navbar {
                @apply bg-transparent p-0;
            }
        }
    }

    .navbar form {
        .form-control {
            @apply h-[40px] py-0 px-0 m-0 text-sm bg-[#F6F6F6] placeholder:text-sm border-[#F6F6F6] focus:border-[#F6F6F6] rounded-full inline-block align-middle;
        }

        .search_result {
            width: 100%;

            ul {
                li {
                    @apply text-sm;
                    &:last-child {
                        @apply mb-0 border-0;
                    }
                }
            }
        }

        .search-button {
            width: 40px;
            height: 40px;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            z-index: 99;

            &.active {
                @apply bg-[#41BDDE];
                img {
                    @apply invert-0 brightness-[1000];
                }
            }
        }

        .search-input {
            position: absolute;
            top: 0;
            right: 0;
            width: 0;
            height: 40px;
            transition: width 0.3s ease, opacity 0.3s ease;
            opacity: 0;
        }

        .search-input.visible {
            @apply w-full;
            opacity: 1;
            transition: width 0.3s ease, opacity 0.3s ease;

            .form-control {
                @apply px-3;
            }
        }
    }
}

@media (max-width: 767px) {
    .sticky-header {
        .deliver-address {
            display: block !important;
        }
        &.sticky .deliver-address {
            position: sticky;
            top: 0;
            display: none !important;
        }
    }
}
